&{
    height: 34.875rem;
}

.marker{
    background-image: url("../img/map_point.png");
    background-color: transparent;
    background-size: cover;
    width: 50px;
    height: 65px;
    border-radius: 50%;
    background-position: center;
    cursor: pointer;
}




@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{

    &{
        height: 20.875rem;
    }
}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}