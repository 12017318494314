
@mixin selection($background, $text){

    /* WebKit/Blink Browsers */
    ::selection {
      background: $background; 
      color: $text;
    }
    
    /* Gecko Browsers */
    ::-moz-selection {
      background: $background; 
      color: $text;
    }

}


@mixin for-phone-up {
  @media (min-width: 551px) { @content; }
}
@mixin for-phone-down {
  @media (max-width: 550px) { @content; }
}
@mixin for-tablet-up {
  @media (min-width: 769px) { @content; }
}
@mixin for-tablet-down {
  @media (max-width: 768px) { @content; }
}
@mixin for-mobile-down {
  @media (max-width: 1024px) { @content; }
}
@mixin for-mobile-up {
  @media (min-width: 1025px) { @content; }
}
@mixin for-wide-down {
  @media (max-width: 1400px) { @content; }
}
@mixin for-wide-up {
  @media (min-width: 1401px) { @content; }
}

