/*	
 * jQuery mmenu offCanvas add-on
 * mmenu.frebsite.nl
 */

@import "../../mixins";
@import "../../variables";


@if ( $mm_opt_offcanvas )
{
	//	Animations
	.mm-page
	{
		box-sizing: border-box;
		position: relative;
	}
	.mm-slideout
	{
		transition: transform $mm_transitionDuration $mm_transitionFunction;
		z-index: 1;
	}

	//	Container, Page, Blocker
	.mm-wrapper_opened
	{
		overflow-x: hidden;
		position: relative;
	}

	.mm-wrapper_background .mm-page
	{
		background: inherit;
	}


	//	Menu
	.mm-menu_offcanvas
	{
		display: none;
		position: fixed;
		right: auto;
		z-index: 0;

		&.mm-menu_opened
		{
			display: block;
		}
	}

	//	IE9 hack
	.mm-menu_offcanvas.mm-menu_opened.mm-no-csstransforms { z-index: 10; } 

	@include mm_offcanvas_size;
}

@if ( $mm_opt_offcanvas_blocker )
{
	.mm-page__blocker
	{
		background: rgba( 3, 2, 1, 0 );
		display: none;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
	}
	.mm-wrapper_blocking
	{
		overflow: hidden;

		body
		{
			overflow: hidden;
		}
		.mm-page__blocker
		{
			display: block;
		}
	}
}
