.mm-panels,
.mm-panels > .mm-panel
{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
}
.mm-panels,
.mm-panel
{
	background: inherit;
	border-color: inherit;
	box-sizing: border-box;
	margin: 0;
}
.mm-panels
{
	overflow: hidden;
}
.mm-panel
{
	-webkit-overflow-scrolling: touch;
	overflow: scroll;
	overflow-x: hidden;
	overflow-y: auto;

	padding: 0 $mm_panelPadding;

	//	Fixes css from other scripts/styles hiding submenus
	&:not( .mm-hidden )
	{
		display: block;
	}

	@include mm_translate( 100%, 0 );
	transition: transform $mm_transitionDuration $mm_transitionFunction;
	transform-origin: top left;

	//	Because padding-top/-bottom is ignored when a DIV is scrollable
	&:before,
	&:after
	{
		content: '';
		display: block;
		height: $mm_panelPadding;
	}
}
.mm-panel_has-navbar
{
	padding-top: $mm_navbarHeight;
}
.mm-panel_opened
{
	z-index: 1;
	@include mm_translate( 0%, 0 );
}
.mm-panel_opened-parent
{
	@include mm_translate( -$mm_subpanelOffset, 0 );
}
.mm-panel_highest
{
	z-index: 2;
}
.mm-panel_noanimation
{
	transition: none !important;

	&.mm-panel_opened-parent
	{
		@include mm_translate( 0, 0 );
	}
}


