

&{
    //background: red;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}
&.-dark{
    .product-container {
        background-color: $second_color!important;
      
    }
    .product-flags{
        background-color: $second_color;
    }
    .product__condition-label{
        color: $light_color;
    }
    .product__condition-value{
        color: $main_color;
    }
    .product-price{
        color: $main_color;
        font-size: 1.375rem;
    }
    .regular-price{
        color: $light_color;
        font-size: 1.125rem;
        i{
            margin-left: .3rem;
            font-size: .9rem;
        }
    }
    .product-name{
         background-color: rgba(35, 35, 38, .9)!important;
         color: $light_color!important;
         text-align: left!important;
         padding: .8rem .7rem!important;
    }
    .img-fluid{
        //width: 23.875rem;
        height: 14.875rem;
        object-fit: cover;
        @include for-phone-down{
            height: 9rem;
        }
    }
  
}

.img-fluid{
    //width: 14.125rem;
    width: 100%;
    height: 15rem;
    object-fit: cover;


    @include for-phone-down{
        height: 9rem;
    }
}



.product-cover-link{
    .only-ie &{
        height: 15rem;
        overflow: hidden;
        display: flex!important;
        align-items: center;

        .img-fluid{
            height: auto;
        }

        @include for-phone-down{
            height: 9rem;
        }
    }
    
}










.slick-prev, .slick-next{
    background-color: transparent;
    background-image: none;
    opacity: 1;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    color: $second_color;
    transition: .2s;
    margin-top: -.7rem;
    box-shadow: 0px 0px 8px 0px $light_color;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:before{
        font-family: $fontawesome5;
        font-size: 1rem;
        width: 38px;
    }
    &:hover{
        transition: .2s;
        background-color: $second_color;
        color: $light_color;
       // color: $second_color;
        //opacity: .7!important;
    }
    @include media-breakpoint-down(md) {
       border: 1px solid;
    //    background-color: $second_color;
    //    color: $light_color;

    top: 30%;
    }
}
.view-all-link{
    padding-left: 0;
    a{
        font-size: 0.875rem;
        color: $second_color;
        border: 1px solid $second_color;
        padding: .45rem 1.5rem;
        display: block;
        font-weight: 500;
        border-radius: 4px;

        padding-top: .5rem;

        
    }
}
.add-to-cart{
    background-color: $main_color;
    color: $second_color;
}


.slick-prev{
    text-align: center;
    right: 49px;
    &:before{
        content: '\f053';
    }
}
.slick-next{

    &:before{
        content: '\f054';
    }
}

.title__block{
    justify-content: space-between;
    padding-right: 6rem;
    align-items: center;
    margin-bottom: 1.5rem;

}

.block__header{
    font-size: 2.25rem;
    color: $second_color;
    font-weight: 400;
    text-transform: uppercase;
    margin-right: 1rem;
}


@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{
    .block__header{
        font-size: 1.8rem;
    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   .title__block{
    flex-direction: column;
    align-items: flex-start;
   }
   .block__header{
       margin-right: 0;
       margin-bottom: 1rem;
       font-size: 1.5rem;
   }

}