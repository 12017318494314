
&{
    background-color: $second_color;
    padding-top: 3rem;
    padding-bottom: 1.8rem;
}

.offers__wrapper{
    max-width: 112.5rem;
    margin: 0 auto;
    padding: 0 1rem;
}
.offer__list{
    display: flex;
}
.offer__item{
    width: 14.2%;
    text-align: center;
    //border: 1px solid $offer_border;
    margin: 0 .1rem;
    display: flex;
    flex-direction: column;
    padding: 1.7rem .5rem;
    flex-wrap: wrap;
}
.offer__icon{
    margin-bottom: 1.1rem;
    svg{
        fill: $main_color;
        width: 5.5rem;
        height: 3.125rem;
    }
}
.offer__name{
    color: $light_color;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 2.2rem;
}
.offer__link{
    
}
.offer__bottom{
    margin-top: auto;
}


@include for-wide-up{
  
}
@include for-wide-down{
    .offer__list{
        flex-wrap: wrap;
        justify-content: center;

    }
    .offer__item{
        width: 20%;
        margin-bottom: 0.2rem;
    }
   
}


@include for-mobile-up{

}
@include for-mobile-down{
    .offer__name{
        font-size: .875rem;
    }
    .offer__item{
        width: 49.5%;

    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
  
}



@include for-phone-up{
   
}
@include for-phone-down{
    .offer__item{
        width: 100%;
        
    }
    &{
        padding-top: 1.5rem;
    }
   
}