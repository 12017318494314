@if ( $mm_opt_vertical )
{

	.mm-listitem_vertical > .mm-panel
	{
		transform: none !important;

		display: none;
		padding: $mm_padding 0 $mm_padding $mm_padding;

		&:before,
		&:after
		{
			content: none;
			display: none;
		}
	}
	.mm-listitem_opened > .mm-panel
	{
		display: block;
	}

	.mm-listitem_vertical
	{
		@if ( $mm_opt_buttons )
		{
			> .mm-btn_next
			{
				box-sizing: border-box;
				height: $mm_btnSize;
				bottom: auto;
			}
		}

		.mm-listitem:last-child:after
		{
			border-color: transparent;
		}
	}
	
	@if ( $mm_opt_buttons )
	{
		.mm-listitem_opened
		{
			> .mm-btn_next:after
			{
				transform: rotate( 225deg );
				right: $mm_listitemIndent - 1;
			}
		}
	}
}