// #cookiesinfo{

// 	// ulozenie wzgledem strony

// 	// position: absolute;
// 	position: fixed;

// 	z-index: 11;
	

// 	// wyswietlaj i nie wyswietlaj

// 	display: none;

// 	&.visible{
// 		display: block;
// 	}

// 	// wymiary i polozenie	

// 	bottom: 3rem;
// 	left: 3rem;
// 	width: 31rem;
// 	padding: 1rem 1.5rem;

// 	@include touch{
// 		width: 100%;
// 		left: 0px;
// 		bottom: 0px;
// 		padding: 0.6rem;
// 		padding-right: 1.5rem; // zeby guzik zamknij mial miejsce
// 	}


// 	// kolory
// 	background-color: #265399;
// 	color: white;
// 	box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);

// 	// typografia

// 	.description{
// 		font-size: 0.8rem;
// 		line-height: 1.2rem;
// 		font-weight: 300;
// 	}

// 	@include touch{
// 		.description{
// 			font-size: 10px;
// 			line-height: 14px;
// 		}
// 	}

// 	// guzik zamknij

// 	.close{
// 		position: absolute;
// 		top: 0.15rem;
// 		right: 0.6rem;
// 		font-size: 1.5rem;

// 		@include touch{
// 			// caly cookies sluzy do zamykania
// 			width: 100%;
// 			height: 100%;

// 			span{
// 				position: absolute;
// 				top: 0px;
// 				right: 0px;
// 			}
			
// 		}
// 	}


// }




.cookies{
	display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 5rem;
    padding-right: 1rem;
    align-items: center;
    background-color: $cookie;
    color: $cookie_text;
    justify-content: center;
    font-size: 0.875rem;

    // @include touch{
    //     align-items: flex-start;
    //     padding: 0.5rem;
    // }

    @include media-breakpoint-down(sm){
        padding-left: 1rem;
    }

    @include for-mobile-down{
        font-size: 0.75rem;
    line-height: 1.3;
    }
}

.cookies:not(.-visible){
    display: none !important;
}

.cookies__text{
	
	font-weight: 300;
    // @include touch{
    //     font-size: 0.6rem;
    //     align-self: center;
    // }
    // @include desktop-only{
    //     font-size: 0.8rem;
    // }

 
}

.cookies__close{
	margin-left: 1rem;
   // width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    span{
        width: 100%;
    }
}