&{
    background-color: $dark_color;
}


.main__slider-progressbar{
    width: 2.375rem;
    height: 2.375rem;
    position: absolute;
    bottom: 2.2rem;
    right: 1%;
    z-index: 2;
}
.main__wrapper{
    position: relative;
    height: 100%;
}

.main__inside-box{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: center;
    top: 0;
}
.main__intro-text{
    font-size: 3rem;
    color: $light_color;
    line-height: 1.35;
    max-width: 41rem;
    margin-top: 8%;
}

.main__silder-item{
    background-repeat: no-repeat;
    background-size: cover;
}
.main__silder-item{
    height: 43rem;
    img{
        display: none;
    }
}
.main__video{
    height: 43.375rem;
    background-position: center;
    
   // background-color: $second_color;

        &:after{
            content: '';
            background-color: $dark_color;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: .55;
            
        }
}



@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    .main__video{
        z-index: 1;
    }
    .main_intro_typed-mobile{
        display: none;
    }
}
@include for-mobile-down{

    .main__intro-text{
        font-size: 2em;
       margin-top: 12%; 


    }
    .main__silder-item{
        height: 27rem;
    }
    .main__slider-progressbar{
        right: 1rem;
    }
    .main__video{
        height: 26.375rem;
        background-image: url('../img/video_bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}



@include for-tablet-up{
   
}
@include for-tablet-down{
   .main__silder-item{
    height: 27rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
       img{
           display: none;
       }
   }
}



@include for-phone-up{
   
}
@include for-phone-down{
    .main__silder-item{
        height: 30rem;
    }
}