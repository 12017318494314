&{
    background-color: $second_color;
    background-image: url('../img/home/newsletter.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    margin-bottom: -.1rem;

}


.newsletter__top{
    margin-bottom: 3.1rem;
}

.newsletter__form{
    max-width: 35.5rem;
    margin-left: auto;
    margin-right: auto;
}
.form__top{
    display: flex;
    margin-bottom: 1.7rem;
}

.newsletter__input-email{
    width: 84%;
    border: none;
    margin-right: .45rem;
    border-radius: 5px 0 0 5px;
    color: $second_color;
    padding: .8rem 1rem;
    font-size: .875rem;
    font-weight: 500;
 

    &::placeholder {
        color: $second_color;
        opacity: 1;
    }	
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $second_color;
        opacity: 1;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $second_color;
        opacity: 1;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $second_color;
        opacity: 1;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $second_color;
        opacity: 1;
    }
}

.newsletter__btn-send{
    width: 16%;
    background-color: $main_color;
    border-radius: 0px 5px 5px 0px;
    font-weight: 500;
    padding: .4rem;
}
.form__label{
    text-align: left;
    color: $light_color;
    font-weight: 400;
    line-height: 1.35;
    font-size: 12px;
    display: flex;

    input[type=checkbox] {
        display:none;
       }
       input[type=checkbox]:checked + span:before {
        font-family: 'Font Awesome 5 Pro';
         content: "\f058";
         font-size: 0.85rem;
         font-weight: 100;
         width: 1rem;

       }
       input[type=checkbox] + span:before {
        font-family: 'Font Awesome 5 Pro';
         content: "\f111";
         font-size: 0.85rem;
         font-weight: 100;
         width: 1rem;
    
       }
       span{
        //  cursor: pointer;
        //  display: inline;
        //  line-height: 1.1rem;
        //  user-select: none;
        display: flex;

            &:before{
                padding-right: 1.4rem;
            }
       }
}




@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{
    &{
        background-position: center;
    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
   .newsletter__input-email{
       width: 65%;
   }
   .newsletter__btn-send{
        width: 35%;
   }
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}