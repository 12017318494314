

&{
   
}
.main-product-details .scroll-box-arrows{
    .left, .right{
        background-color: $main_color;
    }
}
.quick__view-title{
    font-size: 1.5rem;
    letter-spacing: normal;
}
.add-to-cart{
    background-color: $main_color;
    color: $second_color;
}
.product-price{
    color: $second_color;
}
.discount-percentage{
    background-color: $main_color;
    color: $second_color;
}

.close{
    &:hover{
        background-color: $second_color;
    }
}
.product-minimal-quantity-text{
    margin-bottom: 1rem;
}


@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}