

section.checkout-step .step-number{
    color: $main_color;
}

.product-price{
    color: $main_color;
}

.alert-success{
    .done{
        margin-right: 1rem;
    }
}
.address-selector{
    margin-top: 1rem;
}
.add-address{
    margin-bottom: 1rem;
}
.billing-address{
    margin-bottom: 1rem;
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}