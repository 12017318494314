
&{
    padding-top: 6.6rem;
    background-color: $third_color;

    .breadcrumb-wrapper{

        background-color: $third_color;
    }
    
    
}
&.-active-cookie{
    padding-top: 9rem;

}
&.-dark-mode{
    background-color: $second_color;
    color: $third_color;

    .breadcrumb-wrapper{
        background-color: $second_color;
    }
    
}

.breadcrumb{

    .breadcrumb-item{
        font-size: .75rem;
    }
}



@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{
    &{
        padding-top: 3.6rem;
    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}