&{
    background-color: $third_color;
}


.slider__item{
    width: auto;
    margin-right: .2rem;
    margin-bottom: .2rem;
    img{
        
        object-fit: cover;
        display: none;
    }
  
}
.slider__item-link{
    display: flex;
    width: 28.25rem;
    height: 21rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.gallery__btn-box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: 0 3.5rem;
    pointer-events: none;

    button{
        font-size: 3.625rem;
        text-shadow: -2px 1px 21px #191919;
        color: $third_color;
        pointer-events: auto;
    }
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{
    .slider__item-link{
        width: 22.25rem;
        height: 14rem;
    }

   
}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
    .slider__item-link{
        width: 12.25rem;
        height: 8rem;
    }
    .gallery__btn-box{
        padding: 0 1.5rem;
        button{
            font-size: 2rem;
        }
    }
}