&{
    //background-color: green;
}


.product-list-wrapper{
    //różne typy wyświetlania produktów
    &.table-view, &.list{

        .product-name{
            position: static;
            background-color: $light_color;
        }
      
      
    }
    &.table-view{
        .product-miniature .product-thumbnail img{
            height: 5rem;
            object-fit: cover;
        }
        .product-cover-link{
            .only-ie &{
                height: 5rem;
                overflow: hidden;
                display: flex!important;
                align-items: center;
        
                .img-fluid{
                   height: auto;
                }
        
                // @include for-phone-down{
                //   height: 9rem;
                // }
            }
            
        }



    }
    &.list{
        @media (max-width: 33.98em){
            .product-miniature .first-block{
                max-width: 100%;
            }
        }
        .product-miniature .product-thumbnail img{
            height: 12rem;
            object-fit: cover;
        }

        .product-cover-link{
            .only-ie &{
                height: 12rem;
                overflow: hidden;
                display: flex!important;
                align-items: center;
        
                .img-fluid{
                   height: auto;
                }
        
                @include for-phone-down{
                  height: 9rem;
                }
            }
            
        }

    }
  
    &.grid{
        .img-fluid{
            width: 100%;
            height: 15rem;
            object-fit: cover;
        }
        .product-name{
            padding: .8rem .8rem;
        }
        @media (max-width: 33.98em){
   
            max-width: 20rem;
            margin-left: auto;
            margin-right: auto;

           
            .product-miniature{
                flex: 0 0 100%!important;
                max-width: 100%!important;
            }  
        }

        //=====
           
        .product-cover-link{
            .only-ie &{
                height: 15rem;
                overflow: hidden;
                display: flex!important;
                align-items: center;
        
                .img-fluid{
                    height: auto;
                }
        
                @include for-phone-down{
                    height: 9rem;
                }
            }
            
        }

    }
}

.first-block {
   
}




@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}