&{
    background-color: $third_color;
    padding-bottom: 4rem;

}


.parts__intro{
    max-width: 45rem;
    margin-bottom: 3rem;
}



@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}