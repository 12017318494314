



// .mm-listitem{
//   --mm-color-text: red;
// }

$mm_menuMaxWidth : 250px !default;

@import "../lib_new/mmenu/src/core/offcanvas/jquery.mmenu.offcanvas";

@import "../lib_new/mmenu/src/core/oncanvas/jquery.mmenu.oncanvas";

@import "../lib_new//mmenu/src/extensions/positioning/jquery.mmenu.positioning";

//@import "../lib_new/mmenu/src/extensions/themes/mixins";
//@import "../lib_new/mmenu/src/extensions/themes/variables";


$mm_t_cls           : ".mm-menu_theme-dark";
$mm_t_backgroundColor     :$dark_color;
$mm_t_textColor         :$main_color;
$mm_t_dimmedTextColor     : rgba( #fff, 0.4 );
$mm_t_borderColor       : rgba( #000, 0.3 );
$mm_t_emphasizedBackgroundColor : rgba( #000, 0.1 );
$mm_t_highlightedBackgroundColor: rgba( #fff, 0.08 );
$mm_t_navbarColor         : rgba( #fff, 0.4 );
$mm_t_inputBackgroundColor    : rgba( #fff, 0.15 );
$mm_t_pageShadow        : 0 0 20px rgba( #000, 0.5 );
$mm_t_panelShadow       : 0 0 20px rgba( #000, 0.5 );

@if ( $mm_opt_themes_dark )
{
	@include mm_theme_colors;
}

.c__menu-mobile{

    @include for-mobile-up{
        display: none;
    }
}


.menu__mobile-wrapper{

    &.before__init{
        display: none;
    }

    @include for-mobile-up{
       // display: none!important;
    }
    .nav__info-item{
        display: flex;
        align-items: center;
        i{
            margin-right: 1rem;
        }
    }
    .user-info .account-link{
        padding: .4rem 1rem;
    }
}

.mm-menu_offcanvas.mm-menu_opened{
    position: fixed;
}
.mm-sronly{
    display: none;
}
.mm-listitem:after{
    left: 0;
}
// .menu__mobile-wrapper{
//     border-left: 1px solid $main_color;

// }

.mm-listitem_divider{
    padding: 5px 10px 5px 20px;
}
.mmenu__link{
    padding: 10px 10px 10px 20px;
}
.mm-listitem{
    a,>span{
        padding: 10px 10px 10px 20px; 
    }
}

