&{
    background-color: $third_color;
    padding-bottom: 2.5rem;
}


.page__standard-title{
    margin-bottom: 3rem;

}

.page__standard-desc{
   

        p,
    dl,
    ol,
    ul,
    blockquote,
    pre,
    table {
        font-size: 0.94rem;
        &:not(:last-child) {
        margin-bottom: 1em; } }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $second_color;
        font-weight: 500;
        line-height: 1.3 }
    h1 {
        font-size: 2em;
        margin-bottom: 0.5em;
        &:not(:first-child) {
        margin-top: 1em; } }
    h2 {
        font-size: 1.75em;
        margin-bottom: 0.5714em;
        &:not(:first-child) {
        margin-top: 1.1428em; } }
    h3 {
        font-size: 1.5em;
        margin-bottom: 0.6666em;
        &:not(:first-child) {
        margin-top: 1.3333em; } }
    h4 {
        font-size: 1.25em;
        margin-bottom: 0.8em; }
    h5 {
        font-size: 1.125em;
        margin-bottom: 0.8888em; }
    h6 {
        font-size: 1em;
        margin-bottom: 1em; }
    blockquote {

        }
    ol {
        list-style: decimal outside;
        margin-left: 2em;
        margin-top: 1em; }
    ul {
        list-style: disc outside;
        margin-left: 2em;
        margin-top: 1em;
        ul {
        list-style-type: circle;
        margin-top: 0.5em;
        ul {
            list-style-type: square; } } }
    dd {
        margin-left: 2em; }
    figure {
        margin-left: 2em;
        margin-right: 2em;
        text-align: center;
        &:not(:first-child) {
        margin-top: 2em; }
        &:not(:last-child) {
        margin-bottom: 2em; }
        img {
        display: inline-block; }
        figcaption {
        font-style: italic; } 
        }
}
.pages__list{
    background-color: white;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    font-size: .94rem;
    padding: 1rem 1rem;
}
.page__item{
    margin: 0.1rem 0;
    &.-current{

        .page__link{
            background-color: #efefef;
        }
    }
}
.page__link{
    padding: .5rem 0;
    display: block;
    padding-left: .3rem;
    &:hover{
        opacity: 1;
        background-color: #efefef;
        
    }
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    .cms__content-wrapper{
        display: flex;
    }
    .cms__left-nav{
        width: 25%;
        padding-right: 1.5rem;
    }
    .cms__right-content{
        width: 75%;
    }
}
@include for-mobile-down{
    .cms__left-nav{
       display: none;
    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}