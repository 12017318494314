/*
 * jQuery mmenu positioning extension
 * mmenu.frebsite.nl
 */

@import "../../mixins";
@import "../../variables";


@if ( $mm_opt_positioning_right )
{
	.mm-menu_position-right
	{
		left: auto;
		right: 0;
	}
	@include mm_position_right;
}


@if ( $mm_opt_positioning_front or $mm_opt_positioning_top or $mm_opt_positioning_bottom )
{
	.mm-menu_position-front,
	.mm-menu_position-top,
	.mm-menu_position-bottom
	{
		transition: transform $mm_transitionDuration $mm_transitionFunction;

		&.mm-menu_opened
		{
			z-index: 2;

			~ .mm-slideout
			{
				transform: none !important;
				z-index: 0;
			}
			~ .mm-page__blocker
			{
				z-index: 1;
			}
		}
	}

	@if ( $mm_opt_positioning_front )
	{
		.mm-menu_position-front
		{
			@include mm_translate( -100%, 0 );

			@if ( $mm_opt_positioning_right )
			{
				&.mm-menu_position-right
				{
					@include mm_translate( 100%, 0 );
				}
			}
		}
	}
	@if ( $mm_opt_positioning_top or $mm_opt_positioning_bottom )
	{
		.mm-menu_position-top,
		.mm-menu_position-bottom
		{
			width: 100%;
			min-width: 100%;
			max-width: 100%;
		}
	}
	@if ( $mm_opt_positioning_top )
	{
		.mm-menu_position-top
		{
			@include mm_translate( 0, -100% );
		}
		@include mm_position_top;
	}
	@if ( $mm_opt_positioning_bottom )
	{
		.mm-menu_position-bottom
		{
			@include mm_translate( 0, 100% );
			top: auto;
		}
		@include mm_position_bottom;
	}

	.mm-wrapper_opening
	{
		.mm-menu_position-front,
		.mm-menu_position-top,
		.mm-menu_position-bottom
		{
			@include mm_translate( 0, 0 );
		}
	}

}
