&{
    background-color: $second_color;
    color: $light_color;
    background-size: cover;
    background-repeat: no-repeat;

}
&.-home{
    background-image: url('../img/about/about_dark.jpg');
    padding-top: 3rem;
    padding-bottom: 4.5rem;

    .square__item{
        
    }


    .square__link{
        border-color: $main_color;
         &:hover{
         
        
    
            .-no-touch &{
                background-color: $main_color;
                opacity: 1;
    
                .square__title{
                    color: $second_color;
                }
                .square__btn{
                    opacity: 1;
                    border: 1px solid $second_color;
                }
            }
            
         }
    }

    .square__btn{
        border: 1px solid $second_color;
    }










}
&.-subpage{
    background-image: url('../img/about/about_light.jpg');
    color: $second_color;
    padding-bottom: 4.5rem;

    .about__title, .about__intro{
        color: $second_color;
    }
}
.about__intro {
    max-width: 44rem;
}
.about__top{
    margin-bottom: 4rem;
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{
    &{
        background-position: center;
    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}