
&{
    background-color: $second_color;
    color: $light_color;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    

    
}
.nav__logo{
    svg{
        fill: $light_color;
    }
}
&.-sticky-nav{
    box-shadow: -3px -8px 20px 0px $light_color;

    .main-header{
        padding: 0.3rem 0;
    }
    .nav__logo{
        svg{
            width: 4.625rem;
            height: 2.3rem;
        }
    }
    @include for-mobile-down{
   
        box-shadow: -9px -9px 20px 0px $light_color;

        .nav__logo{
            svg{
                width: 4.625rem;
                height: 1.7rem;
            }
        }
    }
}
&.-subpage{

    .main-header{
        padding: 0.3rem 0;
    }
    .nav__logo{
        svg{
            width: 4.625rem;
            height: 2.875rem;
        }
    }

    @include for-mobile-down{

        .nav__logo{
            svg{
                height: 1.7rem;
            }
        }
   
      
    }
}

// hamburger

$hamburger-layer-height        : 0.25em;
$hamburger-layer-spacing       : 0.4em;
$hamburger-layer-border-radius : 0.4em;

$hamburger-layer-border-radius: 0px;
$hamburger-padding-y: 0px;
$hamburger-padding-x: 0px;
$hamburger-layer-color: $main_color;
$hamburger-layer-width: 2em;

// libs
@import '../lib_new/hamburgers/hamburgers.scss';






.nav__info{
    background-color: $dark_color;
    padding: 0.25rem 0;
}

.breadcrumb-wrapper{
    background-color: $second_color;
}
.nav__info-wrapper{
    display: flex;
}
.nav__info-left, .nav__info-right{
    display: flex;
    width: 50%;
}
.nav__info-right{
    justify-content: flex-end;
}
.nav__info-item{
    display: flex;
    align-items: center;
    position: relative;
    i{
        font-size: .9rem;
        margin-right: .7rem;
    }
    .nav__info-text{
        font-size: .875rem;
    }

    &:not(:last-child){
        margin-right: 3.5rem;
        
        &:after{
            position: absolute;
            top: 31%;
            right: -1.9rem;
            content: '';
            width: 2px;
            height: .875rem;
            background-color: $light_color;
            pointer-events: none;
        }

    }

}
.nav__login{
    .btn-teriary{
        border: none;
        &:hover{
            color: $main_color;
        }
    }
    .account-link{
        color: $light_color;
    }
    .customer-logged{
        
       
       
    }
}

.nav__logo{
    &:hover{
        opacity: 1;
    }
    svg{
        max-width: 8.25rem;
        width: 100%;
        height: 5rem;
        transition: .4s;
    }
}

.dropdown-menu{
    background-color: $dark_color;
    border: none;
   

    .dropdown-item{
        color: $light_color;

        
    }
    .dropdown-item:focus,
    .dropdown-item:hover {
         background: $dark_color;
         color: $main_color;
    }
    .logout{
        a{
            color: $main_color;
        }
    }
}
.header-logo{
    max-width: 100%;
    width: 20%;
}
.cart-link{
    background: none;
    font-weight: 500;
    color: $main_color;
    padding: 0;
}
.header-right{
    width: 80%;
    margin: 0;
    margin-right: 1rem;
}
.nav__menu-wrapper{
    display: flex;
    align-items: center;
    position: relative;
}
.nav__menu-left, .nav__menu-right{
    width: 50%;
}
.nav__menu-left{
    display: flex;
    align-items: center;
}

.nav__menu-links{
    display: flex;
    justify-content: flex-end;
}
.nav__menu-item{
    font-size: .94rem;
    &:not(:last-child){
        // margin-right: 2.2rem;
        margin-right: 6%;
    }
 
}
.nav__menu-link{
    &:hover{
        color: $main_color;
        opacity: 1;
    }
}
.search-widget{
    width: 100%;
}
.display-top > div{
    margin-left: 12%;
}
.main-header{
    padding: 1.4rem 0;
    transition: .4s;
}

.nav__menu-item{
    position: relative;
    >a{
        padding: 1rem 0;
    }

    >ul{
        display: none;
       opacity: 0;
       //pointer-events: none;
       transition: .3s;
       position: absolute;
       z-index: 1;
       top: 160%;
       left: -1.2rem;
       width: 14.625rem;
       background-color: $second_color;


    //    &:hover{
    //        pointer-events: none;
    //    }

       >li{
           text-transform: uppercase;
      

           &:not(:last-of-type){
               border-bottom: 1px solid $dark_color;
           }
           a{
             padding: .5rem 1.3rem;
             display: block;
               &:hover{
                    color: $main_color;
               }
           }
       }
    }

    &:hover{
        >ul{
            opacity: 1;
            display: block;
//            pointer-events: auto;

         
        }
    }
}
.mobile-trigger-wrapper{
    position: static;
    margin: 0;
}
.our__hamburger{
    .hamburger{
        display: flex;
        align-items: center;

        &:hover{
            opacity: 1;
        }
    }
}
.hamburger__element{
    background-color: $main_color;

    color: $dark_color;
    font-size: 2.5rem;
    border-radius: 4px;
}
.searchbar__mobile-btn{
    margin-right: 1rem;
    margin-top: .1rem;
    i{
        font-size: 1.3rem;
    }
}
.search__invisible-bg{
    display: none;
    background-color: $dark_color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    content: '';
    height: 100vh;
    opacity: .5;

    &.-active{
        display: block;
    }
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    .search__invisible-bg{
        display: none;

        &.-active{
            display: none;
        }
    }
    // .menu__mobile-trigger{
    //     display: none;
    // }
    // .basket__mobile{
    //     display: none;
    // }

    .nav__mobile-side{
        display: none;
    }
}
@include for-mobile-down{
    &{
        z-index: 2;
    }
    

    .main-header{
        padding: 0.6rem 0;
       
    }
    .nav__menu-right{
        display: none;
    }
    .nav__menu-left{
        width: 100%;
        justify-content: space-between;
    }
    .nav__info-text{
        display: none;
    }
    .nav__info{
        display: none;
    }
    .search__bar-wrapper{
        position: absolute;
        z-index: 101;
        top: 4rem;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        background-color: $dark_color;
        padding: .6rem 1rem;
        transition: .5s;
        top: -18rem;
        margin-right: 0;
        box-shadow: 0px 0px 6px 0px $light_color;

        &.-show-mobile{
            top: 5rem;
        }
    }
    .nav__mobile-side{
        display: flex;
        align-items: center;
    }
    .basket__mobile{
        margin-right: 1rem;
    }
    .nav__logo{
        svg{
            width: 4.625rem;
            height: 2.3rem;
        }
    }
    .display-top > div{
        margin-left: 0;
    }
    .nav__menu-wrapper{
        //position: relative;
    }

    
}



@include for-tablet-up{
   
}
@include for-tablet-down{

}



@include for-phone-up{
   
}
@include for-phone-down{
   
}



