&{
    background-color: $third_color;
}

.opinions__intro{
    max-width: 44.25rem;
    margin-bottom: 2.5rem;
}

.opinions__content{
    color: $second_color;
    ul, ol{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2.5rem;

        li{
            width: 50%;
            padding: 0 2.5rem;
            padding-top: 2.5rem;
            position: relative;
            margin-bottom: 2rem;
            &{
                &:before{
                    position: absolute;
                    top: 0;
                    content: url(../img/quote.png);
                }
            }
        }
        p{
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.4;
            margin-bottom: 1.5rem;
        }
        h1,h2,h3,h4,h5,h6{
            font-size: 1.5rem;
            text-transform: uppercase;
            color: $second_color;
            font-weight: 400;
            margin-bottom: 1rem;
        }
        address{
            color: $main_color;
            font-size: .875rem;
            text-transform: uppercase;
        }
        @include for-mobile-down{
            flex-direction: column;

            li{
                width: 100%;
            }
            p{
                font-size: 1rem;
            }
            h1,h2,h3,h4,h5,h6{
                font-size: 1rem;
            }

        }
    }
}







@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}