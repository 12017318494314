&{
    background-color: $third_color;
    padding-top: 2.8rem;
    padding-bottom: 1.5rem;
}

.parts__top{
    max-width: 45rem;
}

.products__block-2{
    background-color: $light_color;
}
.product-list-wrapper{
    margin-bottom: 0;
}
.parts__top{
   // margin-bottom: 1.5rem;
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}