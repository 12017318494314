//import styli z oryginalnego szablonu




// // bulma
// @import 'lib/bulma-0.7.2/sass/utilities/_all';
// @import 'lib/bulma-0.7.2/sass/base/_all';
// @import 'lib/bulma-0.7.2/sass/elements/_all';
// @import 'lib/bulma-0.7.2/sass/components/_all';
// @import 'lib/bulma-0.7.2/sass/grid/_all';
// @import 'lib/bulma-0.7.2/sass/layout/_all';

// // libs
// // @import 'plugins/hamburgers/hamburgers';
// // @import 'plugins/normalize';

// // base
@import 'base/elements';
@import 'base/mixins';
@import 'base/variables';
@import 'base/settings';
@import 'base/structure';
@import 'classes';

// // components
// @import 'components/backtotop';
@import 'components/cookies';
@import 'components/_menu_mobile';


.c__breadcrumb{
    @import 'components/_breadcrumb';
}
.c__product-item{
    @import 'components/_product_item';
    
}

.c__quick-view{
    @import 'components/_quick_view';
}
.c__backet-menu{
    @import 'components/_basket_menu';
}
.products__block-single{
    @import 'components/_products_slider';
}
.s__gallery{
    @import 'components/_gallery';
}

.products__on-subpage{
    @import 'components/_products_on_subpage';
}

/* ==================== */

// sections

// temp
@import 'temp';

//sections

// #header{
    .s__navigation{
        @import 'pages/_section_navigation';
    }
// }

.s__newsletter{
    @import 'pages/_section_newsletter';
}
.s__contact{
    @import 'pages/_section_contact';
}
.s__footer{
    @import 'pages/_section_footer';
}

.s__map{
    @import 'pages/_section_map'; 
}



/* ========================= */


//home
.s__home-main{
    @import 'pages/home/_home_main';
}
.s__home-offer{
    @import 'pages/home/_home_offer';
}
.s__home-cars{
    @import 'pages/home/_home_cars';
}
.s__home-parts{
    @import 'pages/home/_home_parts';
}
.s__home-opinions{
    @import 'pages/home/_home_opinions';
}

.s__about{
    @import 'pages/about/_about_content';
}


/* subpages */
.s__opinion-content{
    @import 'pages/opinions/_opinion_content';
}
.s__about-single{
    @import 'pages/about_single/_about_single';
}
.s__page-standard{
    @import 'pages/page_standard/_page_standard';
}
.s__cars-content{
    @import 'pages/cars/cars_content';
}
.s__parts-content{
    @import 'pages/parts/_parts_content';
}

.s__category, .s__manufacturer, .s__search {
    @import 'pages/category/_category';
}

.s__product{
    @import 'pages/product/_product';
}
.s__cart{
    @import 'pages/cart/_cart';
}
.s__checkout, .s__order-confirmation{
    @import 'pages/checkout/_checkout';
}
.s__pagenotfound{
    @import 'pages/_pagenotfound';
}