
&{
    
}
.product-flags{
    width: 100%;
    top: 0;
    left: 0;
    background-color: $light_color;
    padding: .4rem .5rem;
   // box-shadow: -3px -8px 20px 0px $second_color;
    position: static;
}
.product__condition{
    font-size: .8rem;
}
.product__condition-label{
    color: $main_color;
    padding-left: .3rem;
    margin-right: .15rem;
}
.product__condition-value{
    color: $second_color;
    font-weight: 600;
}

.quick-view{
    color: $main_color!important;
}
.product-name{
    color: $second_color;
    text-transform: uppercase;
    font-weight: 500;
    position: absolute;
    bottom: 100%;
    width: 100%;
    background: none;
    background-color: rgba(247, 247, 247, .8);

    .product-list .grid &{
        background-color: rgba(247, 247, 247, .8)
    }

   a{
    &:hover{
        color: $main_color;
    }
   }
}
.product-price{
    color: $second_color;
    font-size: 1.125rem;
}
.add-to-cart{
    background-color: $main_color;
    color: $second_color;
}
.add-to-cart.added{
    &:after{
        background: $second_color;
        color: $main_color;
    }
}

.add-to-cart:not(:disabled):not(.disabled):active{
    background: $main_color;
    color: $second_color;
}


.product-price{
  
}






@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}