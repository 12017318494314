// ################ GLOBAL SETTINGS ###########
body{
  font-family: $font_default;
  color: $color_default;
}


@include selection($selection_bgn, $selection_color);



//hover options not for touch devices - detecting by js: touchscreenDetect()
body.-no-touch {
	button, a {
		transition: color .3s ease,
		background-color .3s ease,
		border-color .3s ease,
		opacity .3s ease;
	}
  button:hover, a:hover { opacity: 0.7; }

a[href^='tel:'] {

  pointer-events: none;
}
  
}


button:hover, a:hover { 
  opacity: 0.7; 
  color: unset;
}


// skalowanie strony

html{


    font-size:100%;


@media screen and (max-width: 1400px) {
    font-size: 85%;
}

@media screen and (max-width: 1024px) {
	font-size: 100%
}

}

// obiekt z-index


$z-index: (
  nav:     100,
  overlay: 200,
  popup:   300
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z($key) {
  z-index: z-index($key);
}

// przykład użycia:

// .navigation {
//   @include z(nav);
// }