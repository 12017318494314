&{
    background-color: $second_color;
    color: $light_color;
    position: relative;
    overflow: hidden;
    padding: 4rem 0;

    .page__contact &{
        padding-top: 0.5rem;
    }
}
.contact__wrapper{
    position: relative;
    z-index: 1;
}


.contact__info-box{
    display: flex;
}
.contact__info-item{
    width: 14.25%;
    font-size: 0.875rem;
    &:not(:last-of-type){
        padding-right: 1rem;
    }

}
.contact__info-header{
    color: $main_color;
    margin-bottom: 1.5rem;
}
.contact__info-desc{
    a{
        display: inline-block;
    }
}
.contact__top{
    margin-bottom: 2.1rem;
}
.contact__logo-bg{
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    z-index: 0;
    pointer-events: none;
    user-select: none;
    svg{
        width: 54.75rem;
        height: auto;
        fill: $dark_color;
    }
}
.contact__form-header{
    font-size: 1.5rem;
    color: $form_color;
    font-weight: 400;
    margin-bottom: 2.1rem;
}
.contact__form{
    
}
.form__row-first{
    display: flex;
}
.form__row-second{
    display: flex;
}
.form__field{
    position: relative;
    

    label{
        position: absolute;
        top: .75rem;
        font-size: 1rem;
        left: 1rem;
        margin-bottom: 0;
        transition: .2s cubic-bezier(0.4, 0, 0.2, 1);
        color: $form_color;
        font-weight: 500;
        padding: 0 .3rem;

        &.-expanded{
            top: -.7rem;
            left: .4rem;
            font-size: .75rem;
            background-color: $second_color;
        }
    }
    input{
        width: 100%;
    }
    input, textarea {
        width: 100%;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid $form_color;
        padding: .7rem 1rem;
        color: $light_color;

    }
    textarea{
       
        resize: none;
        max-height: 100%;
        min-height: 100%;
        border-radius: 5px 0 0 5px;
    }
}
.contact__info-box{
    margin-bottom: 2.25rem;
}

.form__field-name, .form__field-phone, .form__field-email{
    width: 33.33%;
}

.form__field-name, .form__field-phone{
    padding-right: .5rem;
}
.form__field-message{
    width: 90%;
    max-height: 4.125rem;
    padding-right: .5rem;
}
.form__btn-send{
    width: 10%;
}
.form__row-first{
    margin-bottom: .875rem;
}
.form__label{
    text-align: left;
    color: $form_color;
    font-weight: 400;
    line-height: 1.35;
    font-size: 12px;

    input[type=checkbox] {
        display:none;
       }
       input[type=checkbox]:checked + span:before {
        font-family: 'Font Awesome 5 Pro';
         content: "\f058";
         font-size: 0.85rem;
         font-weight: 100;
         width: 1rem;

       }
       input[type=checkbox] + span:before {
        font-family: 'Font Awesome 5 Pro';
         content: "\f111";
         font-size: 0.85rem;
         font-weight: 100;
         width: 1rem;
    
       }
       span{
        //  cursor: pointer;
        //  display: inline;
        //  line-height: 1.1rem;
        //  user-select: none;
        display: flex;

            &:before{
                padding-right: 1.4rem;
                margin-top: -.1rem;
                
            }
       }
}
.contact__form-box{
    max-width: 50.5rem;
}
.form__btn-send{
    background-color: $main_color;
    border-radius:  0 5px 5px 0;
    color: $dark_color;
    font-weight: 500;
}
.form__row-second{
    margin-bottom: 1.1rem;
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    
    .contact__info-item{
        min-width: 14.8%;
    }
 
}
@include for-mobile-down{
    .contact__info-item{
        width: 25%;
        margin-bottom: 1rem;

    }
    .contact__info-box{
        flex-wrap: wrap;
    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
    .contact__info-item{
        width: 50%;
    }
    .form__row-first{
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .form__field {
        width: 100%;
        padding-right: 0;
        margin-bottom: .8rem;

        textarea{
            border-radius: 5px;
           
        }
    }

    .form__field-message{
        max-height: 100%;
        height: 9rem;
    }
    .form__btn-send{
        width: 100%;
        border-radius: 5px;
        padding: .7rem;
    }
    .form__row-second{
        flex-wrap: wrap;
    }
    .contact__form-header{
        font-size: 1.25rem;
    }
    .contact__logo-bg{
        bottom: 50%;
        svg{
            width: 28.75rem;
        }
    }
}



@include for-phone-up{
   
}
@include for-phone-down{
    .contact__info-item{
        width: 100%;
        text-align: center;
        padding-right: 0!important;
    }
    .contact__logo-bg{
        right: unset;
        left: 50%;
        transform: translateX(-50%);
       
        svg{
            width: 14.75rem;
        }
    }
    &{
        padding: 2rem 0;
    }
}