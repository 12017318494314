
.l-section{
	width:100%;
}

// uzupelnienie klasy bulmy
// .container:not(.touch-full-width){
// 	padding-left: 1rem;
// 	padding-right: 1rem;
// 	@include touch{
// 		max-width: $mobile_container;
// 	}
// }

// fixed footer

.l-main{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color:white; //dla mmenu
}
main.mm-page{
	background-color:white; 
	overflow: hidden;
}

.l-outside-footer{
	flex: 1 0 auto;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
}


// zewnetrzny kontener
.l-external-container{
    max-width: $external_container_width;
    background-color: $external_container_background;
    margin: 0px auto;
}