&{
    background-color: $second_color;
    color: $light_color;
    padding-top: 3rem;
    padding-bottom: 1.3rem;
}

.footer__links-wrapper{

    .linklist{
        flex: 1!important;
        max-width: 100%!important;
        padding: 0;
        padding-bottom: 1rem;

        h4{
            display: none;
        }
        ul{
            display: flex;
    
            li{
                padding: 0;
                line-height: normal;
                font-size: .875rem;
                text-transform: uppercase;

                position: relative;

              
                &:not(:last-child){
                    padding-right: 4rem;


                    &:after{
                        position: absolute;
                        top: 13%;
                        right: 2rem;
                        content: '';
                        width: 2px;
                        height: .875rem;
                        background-color: $light_color;
                        pointer-events: none;
                    }
                }
                a{
                    &:before{
                        display: none;
                    }
                    
                }
            }
        }
    }
    
}
.footer__info-link{
    display: inline-flex;
    align-items: center;

    i{
        margin-right: .7rem;
    }
}
.footer__bottom{
    display: flex;
    justify-content: space-between;
    color: $form_color;
}
.footer__social{
    margin-bottom: .8rem;
}



@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{
    .footer__bottom{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer__links-wrapper{

        .linklist{
        
            ul{
              flex-wrap: wrap;
              justify-content: center;
        
                li{
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .footer__social{
        text-align: center;
    }
}




@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
    .footer__links-wrapper{

        .linklist{
        
            ul{
                flex-direction: column;
                align-items: center;
                li{
                 
                   
                    &:not(:last-child){
                        padding-right: 0;


                        &:after{
                                display: none;
                        }
                    }
                }
            }
        }
    }
}