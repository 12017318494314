
&{
    background-color: $third_color;
    color: $second_color;
    position: relative;
}
.about__single-top{
    max-width: 44.25rem;
}
.about__single-desc{
    font-size: 0.94rem;
    padding-bottom: 3rem;
    

    h1,h2,h3,h4,h5,h6{
        color: $second_color;
        font-size: 1.25rem;
        font-weight: 200;
        line-height: 1.35;
        margin-bottom: 1.5rem;
    }
  
}

.about__single-container{
    position: relative;
    z-index: 1;
}
.bg__logo{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: .5rem;
    z-index: 0;
    pointer-events: none;
    user-select: none;
}
.about__special-content{
    text-align: center;
    margin-top: 8rem;
    padding-bottom: 2.5rem;
}


@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{
    .bg__logo{
        svg{
            width: 17rem;
        }
        bottom: -0.5rem;
        top: unset;
    }

}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}