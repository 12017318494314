&{
    //background-color: red;
    margin-top: -1.2rem;
}
.small-description{
    font-size: .94rem;
    color: $second_color;
}


.left-categories{
    ul{
       // background: red;
    }
}
.column-block{
    background-color: $light_color;
    border-radius: 5px;
}
.column-title{
    color: $second_color;
    letter-spacing: normal;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 1rem 1rem;
}
.pagination-wrapper{
    background-color: $third_color;
}

.pagination .page-list .current a{
    background-color: $second_color;
}
.pagination .page-list a{
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
}

.category-sub-menu{
    border: none;
}
.form-control-label{
    font-weight: 600;
}


/* drzewko */
.category-tree{


  >ul{
      
    >li{
        margin-bottom: .75rem;
        >a{

            display: inline-flex;
            padding-bottom: .5rem;

            &:before{
                font-family: $fontawesome5; 
                //content: '\f192';
                content: '\f111';
                margin-right: .4rem;
                font-size: .8rem;
            }
            &.current{
                
                &:before{
                    font-weight: 400;
                    font-family: $fontawesome5; 
                     content: '\f192';;
                } 
            }
            
        }
        .category-sub-menu{
            ul{
                margin-left: -.7rem;
                li{
                    padding: 0;
                    a{
                        
                        padding: .5rem 1rem;
                        padding-left: 2rem;
                        display: block;

                        &.current{
                            background-color: $category_active;
                        }
                    }

                }
            }
        }
    }
  }

  li{
      a{
          font-size: .94rem;
      }
  }

} 


@media (min-width: 992px){
    #left-column{
        flex: 0 0 28.5%;
        max-width: 28.5%;
    }
    
    #center-column{
        flex: 0 0 71.5%;
        max-width: 71.5%;
    }
}


@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}