&{
   #wrapper &{
       margin-top: -1rem;
   }
   color: $second_color;
}

.product__title{
    font-size: 1.5rem;
    letter-spacing: normal;
    line-height: 1.2;

}


.main-product-details{
    padding-top: 2rem;
}
.product-attributes{
    margin-bottom: 3rem !important;
}
.product-information{
    border: none;
    padding: 0;
    display: flex;
    align-items: center;

    .only-ie &{
      // flex-direction: column;
    }
}

.product-minimal-quantity{
    display: none;
}
.product-price{
    color: $second_color;
}
.discount-percentage{
    background-color: $main_color;
    margin-top: 0.4rem;
    color: $second_color;
}

.product-add-to-cart{
    display: flex;

    .only-ie &{
        align-items: center;
      }
}
.add-to-cart{
    background-color: $main_color;
    color: $second_color;
    text-transform: none;
    font-weight: 500;
    min-width: 150px!important;

    .only-ie &{
        margin-left: 3rem;
    }

}
.main-product-details .product-quantity .qty{
    width: 4.75rem;
}



.product-flags{
    z-index: 100;
}
.product__desc-short{
    font-size: 0.94rem;
    color: $second_color;
    font-weight: 400;
    margin-bottom: 2.5rem;
}


.product__gallery{
    max-width: 22.75rem;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    margin-bottom: 2.5rem;
    z-index: 3;
    user-select: none;
    position: relative;
}
.gallery__big{
    margin-bottom: 1rem;
}
.product__big-image{
    //background-color: #ffffff;
    cursor: pointer;
    border: 1px solid grey;
    display: inline-flex;

    .only-ie &{
        height: 18rem;
        display: flex;
        align-items: center;
        overflow: hidden;
        background-color: white;

        img{
            height: auto;
        }
    }


    img{
        height: 18rem;
        width: 100%;
        object-fit: cover;
    }
}
.product__small-image{
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;



   // margin: 0 .1rem;

    &.-clicked{
        border-color: $main_color;
    }

    img{
        width: 100%;
        height: 4rem;
        object-fit: cover;
    }

    .only-ie &{
        height: 4rem;
        display: flex;
        align-items: center;
        overflow: hidden;

        img{
            height: auto;
        }
    }
}


.gallery__small{
    position: relative;
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
}

.gallery__small-btn-prev, .gallery__small-btn-next{
    background-color: $main_color;
    font-size: 1.1rem;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $light_color;
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);

    i{
        font-weight: 500;
    }
}
.gallery__btn-content{
    text-align: center;
    width: 100%;
}
.swiper-button-disabled{
    background-color: gray;
    pointer-events: none;
     opacity: 1;
 }

.gallery__small-btn-prev {
    left: -.7rem;
}
.gallery__small-btn-next {
    right: -.7rem;
}

.main-product-details .product-attributes .attribute-item label{
    min-width: 16%;
}
.attribute-item{
    label{
        font-size: .875rem;
        color: $second_color; 
      
    }
    span{
        font-size: .875rem;
        color: $second_color; 
        font-weight: 500;
    }
}

/* .social-sharing{
    .facebook{
        a{
            color: #1353b3;
        }
    }
} */
.main-product-details .product-prices .tax-shipping-delivery-label{
    font-size: .875rem; 
    font-style: normal;
}
.form-control-label{
   font-size: .875rem;
   color: $second_color;
}
.form-control{
    color: $second_color;
    font-weight: 600;
}
.attribute-item{
 
    &:not(:last-of-type){
        margin-bottom: .4rem;
    }
}

.product-description, .extra-content{
    font-size: .94rem;
    color: $second_color;
}
.title-block{
    text-transform: uppercase!important;
    color: $second_color;
    letter-spacing: 0;
}
.product-description-block{
    margin-bottom: 3.5rem;

    img{
        margin: 1rem 0;
    }
}
.main-product-details{
    margin-bottom: 2.5rem;
}
.product__title-also{
    text-transform: none!important;
    margin-bottom: 2rem;
}
.product-features{
    .data-sheet{
        .name, .value{
            padding: 1rem 1.6rem;
            background-color: $border!important;
            font-size: .875rem;
            font-weight: 500;
            margin-bottom: .375rem;
        }
        .value{
            margin-left: .375rem;
        }
    }
}
.product-accessories{
    padding-top: 3.5rem;
}




@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    .product-actions{
        width: 60%;
    }
    .product-prices{
        width: 40%;
    }
    .product-description-block{
        max-width: 44rem;

    }
}
@include for-mobile-down{
    .product-information{
       display: block;
    }
    .product-add-to-cart{
        justify-content: center;
    }
    .main-product-details .product-prices{
        justify-content: center;
        text-align: center;
    }
    .align-items-center{
        justify-content: center;
        
    }
}



@include for-tablet-up{
   
}
@include for-tablet-down{
   .product-add-to-cart{
    flex-direction: column;
    align-items: center;
   }
   .product__gallery{
       margin-bottom: 0;
   }
}



@include for-phone-up{
   
}
@include for-phone-down{
   .product__title{
       font-size: 1.25rem;
   }
}














@media (min-width: 768px){
    .product-left{
        flex: 0 0 36.66667%;
        max-width: 36.66667%;
    }
    .product-right{
        flex: 0 0 63.33333%;
        max-width: 63.33333%;
    }
}