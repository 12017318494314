@if ( $mm_opt_buttons )
{
	.mm-btn
	{
		box-sizing: border-box;
		width: $mm_btnSize;
		height: $mm_btnSize;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	.mm-btn_fullwidth
	{
		width: 100%;
	}

	.mm-btn_clear,
	.mm-btn_close
	{
		@include mm_btn_cross();
	}

	.mm-btn_prev:before,
	.mm-btn_next:after
	{
		@include mm_btn_arrow;
	}
	.mm-btn_prev:before
	{
		@include mm_btn_arrow_prev;
	}
	.mm-btn_next:after
	{
		@include mm_btn_arrow_next;
	}
}
