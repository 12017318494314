

// fonty
$font_default: 'Ubuntu', sans-serif;
$color_default: rgb(0,0,0);
$fontawesome5: 'Font Awesome 5 Pro';

// selekcja
$selection_bgn: black;
$selection_color: white;


// scroll
$scroll_width: 17px;

$external_container_width: 2100px;
$external_container_background: white;
$mobile_container: 600px;

$main_color: #ffd400;
$second_color: #232326;
$third_color: #f7f7f7;
$light_color: #ffffff;
$dark_color: #19191b;


$cookie: #555963;
$cookie_text: #a0a5b5;

$offer_border: #39393d;
$form_color: #6e6f72;

$category_active: #efefef;
$border: #e8e8e8;
$label: #797979;

$error_color: salmon;

/* 
    breakpoint from presta

  xs: 0,
  sm: 34em,    // 544px
  md: 48em,    // 768px
  lg: 64em,    // 1024px
  xl: 81.25em  // 1300px

  usage
   @include media-breakpoint-down(sm){
       
   }

  
  */

