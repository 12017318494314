


.products__block-6{
    background-color: $third_color;
}

&{
    padding-top: 3rem;
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}