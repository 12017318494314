&{
    background-color: $third_color;
}


.cart-dropdown-wrapper{
  
}


.price-total{
    color: $second_color;
}
.st-menu-close{
    left: auto;
    right: 1rem;
    font-size: 1.875rem;
}
.cart-title{
    font-size: 1.875rem;
    background-color: $second_color;
    padding: 1.7rem 1rem;
    padding-left: 2rem;
    position: relative;
    
}
.cart__title-text{
    text-transform: none!important;
    color: $light_color;
}
.st-menu-close{
    color: $light_color;
    font-weight: 400;
    top: 1.6rem;
    // transform: translateY(-50%);

    i{
        font-weight: 200;
    }
}


.product-image{
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px;
    align-self: flex-start;
    overflow: hidden;
}

.cart-items{
    background-color: $third_color;
    padding: 1rem 2rem;
    padding-bottom: 0;
 
}

.cart-product-line{
    background-color: $light_color;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0px 0px 1px 0px $second_color;
    margin-bottom: .4rem;
}
.cart-bottom{
    padding: 1rem 2rem;
    border: none;
}
.btn__final-order{
    background-color: $main_color;
    color: $second_color;
    font-weight: 500;
    &:hover{
        color: $second_color;
    }
}
.sidebar-currency{
    border: none;
}
.total-line{
    border-bottom: 1px solid $border;

    .label{
        font-size: .875rem;
        color: $second_color;
        font-weight: 500;
    }
    .value{
        font-size: 1rem!important;
    }
}
.cart-total{
    border: none;
}

.product-name{
    color: $second_color;
    font-size: 1rem;
    margin-bottom: .7rem;
    &:hover{
        color: $second_color;
    }
}
.product-price{
    color: $second_color;
}
.product__quantity-label, .product__price-label{
    color: $label;
    font-size: .875rem;
    font-weight: 500;
    min-width: 2.4rem;
    display: inline-block;
}
.product-quantity{
    margin-bottom: 1rem;
}
.product__quantity-value{
    font-size: .875rem;
}
.product__price-value{
    font-size: 1.25rem;
}

@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    &{
        width: 424px;
    }
}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}