@import 'lib/bourbon/bourbon';
@import "partials/_variables";
@import "lib/bootstrap/bootstrap";
//@import "bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";

@import "partials/_mixins";


// Colors

$gray-dark-text: #bebebe;
$gray-dark-title: #bbcdd1;
$gray-dark-hover: #282b30;
$background-grey: #eaebec;
$gray-dark-text-hover: #ffffff;
$color-separator: #dfdfdf;

// Sizes

$size-navbar-width: 13.13rem;
$size-navbar-width-mini: 3.125rem;
$size-navbar-link-height: 1.75rem;
$size-header-height: 2.5rem;
$size-header-toolbar-height: 5.313rem;
$header-mobile-padding-y: .625rem;

// Imported from bootstrap

$grid-gutter-width: 1.875rem !default;

$border-radius: 0;

//UI Kit variables - to be removed
$gray-soft: #FAFBFC;
$gray-light: #BBCDD2;
$medium-gray: #6c868e;
$gray-medium: $medium-gray;
$gray-dark: #363a41;
// $primary: #25b9d7;
$primary: #ffd400;
$primary-hover: #3ED2F0;
$secondary: $medium-gray;
$secondary-hover: $gray-dark;
$notice: #DFF5F9;
$success: #78D07D;
$warning: #CD9321;
$warning-hover: #FDE7BB;
$danger: #c05c67;
$danger-hover: #fde1e1;
$brand-primary: $primary;
$brand-secondary: $secondary;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$font-size-base: .875rem;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-size-xs: 0.625rem;
$font-size-h1: 1.5rem;
$nav-tabs-active-link-hover-border-color: $brand-primary;
$link-color: $brand-primary;
$brand-success: $success;
$brand-danger: $danger;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: #fff;
$spacing-base: 30px;
$spacing-widths: (
  xxs: $spacing-base / 6,
  xs: $spacing-base / 3,
  s: $spacing-base / 2,
  n: $spacing-base,
  l: $spacing-base * 2
);
$grid-breakpoints: (
  xs: 0,
  sm: 34em,    // 544px
  md: 48em,    // 768px
  lg: 64em,    // 1024px
  xl: 81.25em  // 1300px
);

$card-border-color: #e5e5e5 !default;
$module-logo-size: 45px;

$card-spacer-x: 1.25rem !default;
$card-spacer-y: .75rem !default;
$card-border-width: 1px !default;
$card-border-radius: $border-radius !default;
$card-border-radius-inner: $card-border-radius !default;
$card-cap-bg: #f5f5f5 !default;
$card-bg: #fff !default;
$card-link-hover-color: #fff !default;
$enable-rounded: true !default;
$enable-hover-media-query: true;

$btn-primary-bg: $primary-hover;
$btn-secondary-border: #000;
$btn-info-bg: $brand-info !default;
$btn-success-bg: $brand-success !default;
$btn-warning-bg: $brand-warning !default;
$btn-danger-bg: $brand-danger !default;






@import "partials/_fonts";
@import "partials/_commons";

@import "components/alert";
@import "components/drop-down";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/quickview";
@import "components/stores";
//@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/ps-modules";

@import "lib/slick.css";
@import "lib/slick-theme";
@import "lib/pace";
@import "lib/st-menu";
@import "lib/colpick.css";
@import "lib/magnific-popup.css";
@import "lib/ui-tooltip";

@import "aone/aone-modules";

@import "components/lang-rtl";
@import "components/print";




@import "new/main";

