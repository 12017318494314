@if ( $mm_opt_navbar )
{
	.mm-navbar
	{
		border-bottom: 1px solid;
		border-color: inherit;
		text-align: center;
		line-height: $mm_navbarHeight / 2;

		display: none;
		height: $mm_navbarHeight;
		padding: 0 $mm_btnSize;
		margin: 0;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		
		opacity: 1;
		transition: opacity $mm_transitionDuration $mm_transitionFunction;

		> *
		{
			box-sizing: border-box;
			display: block;
			padding: ( $mm_navbarHeight / 4 ) 0;
		}
		a,
		a:hover
		{
			text-decoration: none;
		}
	}

	.mm-navbar__title
	{
		@include mm_ellipsis;
	}
	.mm-navbar__btn
	{
		&:first-child
		{
			left: 0;
		}
		&:last-child
		{
			text-align: right;
			right: 0;
		}
	}
	.mm-panel_has-navbar
	{
		.mm-navbar
		{
			display: block;
		}
	}
}