
.text__normal{

	ul
	{
		list-style: disc;
	}

	ul, ol{
		font-size: 14px;
		padding-left:1.4rem;
		padding-bottom:1.5rem;
		line-height: 1.6;
	}
	p{
		font-size: 14px;
		padding-bottom:1.5rem;
	    line-height: 1.6;
	}

	p, ul,ol{
		&:last-child{
			padding-bottom:0;
		}
	}
	p, ul,ol{
		&:last-of-type{
			padding-bottom:0;
		}
	}

	p{
	
	}


}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: normal;
    font-family: $font_default!important;
}

// polprzezroczyste tlo
.lg-backdrop{
	background-color: rgba(black, 0.8);
}

.lg-outer .lg-thumb-item{
	border-radius: 0;
  }

  
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover{
	border-color: $main_color;
  }
  .lg-outer .lg-thumb{
	  margin-left: auto;
	  margin-right: auto;
  }
.ui-pnotify-closer{
    visibility: visible!important;
}

.brighttheme-error.ui-pnotify-container{
	background-color: $error_color;

	background-image: none;

	
  }

// blokada scroll
body.-block-scroll{
	overflow: hidden; 
}

body.-no-touch.-block-scroll {

    padding-right: $scroll_width;
    
    .-fixed{
        padding-right: $scroll_width;
    }

}


.input-group-btn{
    .btn{
        display: flex;
        height: 100%;
    }
}

.form-control{
   // width: 93%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    i{
        color: $dark_color;
    }
}
.search-widget{
    .btn-primary{
        background-color: $main_color;
    }
    form{
        display: flex;

        input[type="text"]{
            margin-right: 0.4rem;
            padding-right: 5%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    form [type="submit"]{
        position: static;
        
    }
   
}

.search__input{

    font-weight: 600;
    color: $dark_color!important;

    &::placeholder {
        color: $dark_color;
        opacity: 1;
    }	
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $dark_color;
        opacity: 1;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $dark_color;
        opacity: 1;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $dark_color;
        opacity: 1;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $dark_color;
        opacity: 1;
    }

}
.search__btn{
    //padding: 0 1rem; 
    width: 3.2rem!important;

    i{
        color: $dark_color;
    }
}

.ui-autocomplete.ui-widget-content{
    position: fixed;
}
.cart-preview .cart-product-line .remove-from-cart{
    color: #797979;
    font-size: 1rem;
}


.zoomContainer{
    display: none;
}

.input-group{
   // background: red;
    height: 2.25rem;
    .form-control{
        height: 100%;
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .input-group-btn-vertical{
        display: block;
        margin-left: -.1rem;
        height: 100%;
        .btn-touchspin{
            display: flex;
            //height: 50%;

            .only-iphone &{
                
            }
        }
    }
}


.price-total {
    color: $second_color;
  }
//============

.g__section-title{
    font-size: 2.25rem;
    color: $second_color;
    margin-bottom: 1.375rem;
    letter-spacing: 1.1px;
    position: relative;
    padding-bottom: 1.4rem;

    &:after{
        content: '';
        height: 0.75rem;
		
        width: 2.5rem;

        body.index &{
            height: 0;
        }
		// @include touch{
		// height: 0.5rem;
		// width: 2rem;			
		// }
		background-color: $main_color;
		display: block;
        margin-top: .7rem;
        transition: cubic-bezier(0.62, 0.51, 0.9, 0.79) 1.4s;
        position: absolute;
        bottom: 0;
        left: 0;

     
    }

    &.-draw-line{

        body.index &{
            &:after{
                height: 0.75rem;
            }
        }
    }
    &.light{
        color: $light_color;
    }

    @include for-tablet-down{
        font-size: 1.75rem;  
    }
    @include for-phone-down{
        font-size: 1.5rem;  

        &:after{
            margin-top: .4rem;
            height: 0.4rem;
            width: 2.5rem;
        }
    }

  


 
}
.g__section-subtitle{

}
.g__section-intro{
    color: $second_color;
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.35;

    &.light{
        color: $light_color;
    }

    @include for-tablet-down{
        font-size: 1.1rem;  
    }
}

.btn__normal{
    background-color: $main_color;
    color: $dark_color;
    font-size: 0.875rem;
    padding: .4rem 1rem;
    display: inline-block;
    border-radius: 4px;
    font-weight: 500;

}

.btn__new{
    position: relative;
    background: $main_color;
    color: $second_color;
    //border: 1px solid $main_color;
    font-size: 0.875rem;
    padding: .4rem 1rem;
    display: inline-block;
    border-radius: 4px;
    font-weight: 500;
    transition: .6s;
    overflow: hidden;
    &:focus{
        outline: none;
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        background: rgba(0,0,0, .9);
        width: 60px;
        height: 100%;
        left: 0;
        top: 0;
        //opacity: .7;
        filter: blur(30px);
        transform: translateX(-130px) skewX(-15deg);
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        background: rgba(0,0,0, .5);
        width: 30px;
        height: 100%;
        left: 30px;
        top: 0;
        opacity: 0s;
        filter: blur(30px);
        transform: translateX(-100px) skewX(-15deg);
    }
    &:hover{
        background: $main_color;
        cursor: pointer;
        opacity: .8!important;

        &:before{
            transform: translateX(300px) skewX(-15deg);
            opacity: .9;
            transition: 2.5s;
        }
        &:after{
            transform: translateX(300px) skewX(-15deg);
            opacity: 1;
            transition: 3s;
        }
    }
}


.square__list{
    display: flex;
    //margin: 0 -4.3125rem;
    justify-content: center;
    margin: 0 -4rem;
    flex-wrap: wrap;

}

.square__item{
    width: 33.33%;
    
   // margin: 0 4.3125rem;
    min-height: 19.375rem;
    padding: 0 4rem;
}
.square__link{
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 1.5rem 1rem;
    border: 1px solid $second_color;
    position: relative;

     &:hover{
     
        opacity: 1;
        .square__svg-box{
            svg{
                fill: $dark_color;
            }
        }

        .-no-touch &{
            background-color: $second_color;
            opacity: 1;

            .square__title{
                color: $light_color;
            }
            .square__btn{
                opacity: 1;
            }
        }
        
     }
}
.square__title{
    font-size: 1.875rem;
    line-height: normal;
    margin-bottom: 5rem;
    text-align: center;
    font-weight: 500;

    &.-upper{
          text-transform: uppercase;  
    }

  
}
.square__content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 1;
   
}
.square__btn{
    .-no-touch &{
        opacity: 0;
        transition: .5s;
    }
    &:hover{
        opacity: .7;
    }
}
.square__svg-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg{
        width: 15rem;
        height: 12rem;

        fill: #ebebeb;
    }
}


@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    .square__title{
        &.-upper{
            font-size: 2.25rem;
        }
    }
}
@include for-mobile-down{
    .c__backtotop{
        display: none!important;
    }
    .square__svg-box{
        svg{
            width: 11rem;
            height: 9rem;
        }
    }
    .basket__text{
        display: none;
    }
    .ui-autocomplete.ui-widget-content{
        max-width: 260px;
        max-height: 8rem;
        overflow-y: scroll;
        overflow-x: hidden;
        //display: block!important;
        //z-index: 10;
    }
    .ui-autocomplete.ui-menu .ui-menu-item{
        white-space: normal;
    }

    .square__list{
        margin: 0;
        max-width: 20rem;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
    .square__item{
        //margin: 0 1rem;
        padding: 0;
        width: 100%;
        margin: 0;
        min-height: auto;
        margin-bottom: 1rem;
    }
    .square__title{
        font-size: 1.25rem;
    }
   

}



@include for-tablet-up{
    
   
}
@include for-tablet-down{
   .square__item{
      
   }
   .square__list{
      
   }
   
}



@include for-phone-up{
   
}
@include for-phone-down{

}