&{
    background-color: $third_color;
    padding-bottom: 4rem;
}


.cars__intro{
    margin-bottom: 3rem;
}

.car__categories-list{
   
}


@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
 
}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}