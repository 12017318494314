

.searchbar-wrapper{
    margin-top: 1rem;
}



@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{

}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}