&{
    padding: 3rem 0;
}

.opinions__intro{
    max-width: 44.25rem;
    margin-bottom: 2.6rem;
}

.opinions__content{
    color: $second_color;
    max-width: 44.25rem;
    &.before__init{
        ul, ol{
            li{
                &:not(:first-of-type){
                    display: none;
                }
            }
        }
    }

    ul, ol{
        list-style: none;
   

        li{
           
            padding-top: 2.5rem;
            position: relative;
            margin-bottom: 2rem;
            text-align: center;


            
            &:before{
                position: absolute;
                top: 0;
                right: 3%;
                content: url(../img/quote.png);
            }
         
            

        }
        p{
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.4;
            margin-bottom: 1.5rem;
        }
        h1,h2,h3,h4,h5,h6{
            font-size: 1.5rem;
            text-transform: uppercase;
            color: $second_color;
            font-weight: 400;
            margin-bottom: 1rem;
        }
        address{
            color: $main_color;
            font-size: .875rem;
            text-transform: uppercase;
        }
        @include for-mobile-down{
         

            li{
               
            }
            p{
                font-size: 1rem;
            }
            h1,h2,h3,h4,h5,h6{
                font-size: 1rem;
            }

        }
    }
}

.btn__more{
    font-size: 0.875rem;
    color: $second_color;
    border: 1px solid $second_color;
    padding: .45rem 1.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    border-radius: 4px;
    
}

.slide__button-prev, .slide__button-next{
    display: block;
    background-color: transparent;
    background-image: none;
    opacity: 1;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    color: $second_color;
    transition: .2s;

    &:hover{
        opacity: 1!important;
        transition: .2s;
        background-color: $second_color;
        color: $light_color;
       // color: $second_color;
        //opacity: .7!important;
    }

}
.slider__btn-wrapper{
    display: flex;
    margin-left: .6rem;
}
.opinion__bottom{
    display: flex;
    justify-content: flex-end;
}


@include for-wide-up{
  
}
@include for-wide-down{

   
}


@include for-mobile-up{
    .opinion__bottom{
        margin-top: -2rem;
    }
  
}
@include for-mobile-down{


}



@include for-tablet-up{
   
}
@include for-tablet-down{
   
}



@include for-phone-up{
   
}
@include for-phone-down{
   
}